<template>
  <v-card
    class="rounded-xl mx-auto ml-3 pa-2 "
    outlined
    tile
  >
    <v-card-title class="mb-1 text-subtitle-1 color-blue">
      {{ titulo }}
    </v-card-title>
    <v-card-text
      class="py-0"
      style="flex: 1"
    >
      {{ subtitulo }}
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        :href="path"
        target="_self"
        color="primary"
        depressed
        small
      >
        Acessar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      default: '',
    },
    subtitulo: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>

</style>
