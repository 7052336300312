<template>
  <v-container class="fill-height fluid">
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
      >
        <TheLayoutPageTitle :title="$t('aerodromo.titulo')" />
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <AerodromoCard
              :titulo="$t('aerodromo.cardAlteracao.titulo')"
              :subtitulo="$t('aerodromo.cardAlteracao.subtitulo')"
              path="/aerodromo/alteracao"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <AerodromoCard
              :titulo="$t('aerodromo.cardExploracao.titulo')"
              :subtitulo="$t('aerodromo.cardExploracao.subtitulo')"
              path="/aerodromo/exploracao"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <AerodromoCard
              :titulo="$t('aerodromo.cardInscricao.titulo')"
              :subtitulo="$t('aerodromo.cardInscricao.subtitulo')"
              path="/aerodromo/inscricao"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <AerodromoCard
              :titulo="$t('aerodromo.cardPlanoDiretor.titulo')"
              :subtitulo="$t('aerodromo.cardPlanoDiretor.subtitulo')"
              path="/aerodromo/plano-diretor"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheLayoutPageTitle from '../layout/TheLayoutPageTitle.vue';
import AerodromoCard from './AerodromoCard.vue';

export default {
  components: { AerodromoCard, TheLayoutPageTitle },
  data: () => ({
    justify: [
      'center',
    ],
  }),
};
</script>
